import { Alert, CardContent, Grid, Stack, Typography } from '@mui/material';
import type { Event } from 'core';
import type { FormikProps } from 'formik';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import { FormikDateTimeField } from '../../../../components/formik-fields/date-time-field.js';
import { FormikLocationField } from '../../../../components/formik-fields/location-field.js';
import { FormikLocationMapField } from '../../../../components/formik-fields/location-map-field.js';
import { FormikRadioGroup } from '../../../../components/formik-fields/radio-group.js';
import { FormikSwitch } from '../../../../components/formik-fields/switch.js';
import { FormikTextField } from '../../../../components/formik-fields/text-field.js';
import { SelectGroups } from '../../../../components/select-groups/select-groups.js';
import type { EventDetailsState } from '../../store/event-details-state.js';

export type FormData = Partial<Event.Create>;

interface Props {
  edit?: boolean;
  formik: FormikProps<EventDetailsState['all']>;
}

export const EventDetailsStepView: FC<Props> = ({ edit, formik }) => {
  return (
    <>
      <CardContent>
        <Typography variant="h4">Event Details</Typography>
      </CardContent>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormikTextField
              id="title"
              label="Event Title"
              formik={formik}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <FormikRadioGroup
              id="type"
              label="Event Type"
              formik={formik}
              defaultValue="long"
              items={[
                { id: 'long', display: 'Long/Medium' },
                { id: 'sprint', display: 'Sprint' },
                { id: 'rogaine', display: 'Rogaine' },
                { id: 'rogaine/points', display: 'Rogaine (Points)' },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikSwitch
              id="public"
              label="Public Event"
              formik={formik}
              helperText="Events that are public will be visible to all users. Any track recorded that matches the time and location will be a potential match. Non-public events are by invite only."
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormikSwitch
              id="competition"
              label="Competition"
              formik={formik}
              helperText="For a competition, details of courses and controls will not be visible until after the event has finished."
            />
          </Grid>
          {!formik.values.public ? (
            <Grid item xs={12}>
              <SelectGroups formik={formik} />
            </Grid>
          ) : null}
          {!edit ? (
            <Grid item xs={12}>
              <FormikSwitch
                id="published"
                label="Published"
                formik={formik}
                helperText="When the event is published it will be visible to other users."
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Typography variant="h4">Event Timing</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikDateTimeField
              id="start"
              label="Start From"
              formik={formik}
              referenceDate={DateTime.now().minus({ days: 1 }).toJSDate()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikDateTimeField
              id="finish"
              label="Start Until"
              formik={formik}
              referenceDate={formik.values.start}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikDateTimeField
              id="close"
              label="Course Close"
              formik={formik}
              referenceDate={formik.values.start}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Location</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              <FormikLocationField
                id="location"
                label="Finish Location"
                formik={formik}
                fullWidth
              />
              <Alert severity="info">
                The times, along with the event finish location above, are used
                to automatically match recorded tracks with this event.
              </Alert>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormikLocationMapField id="location" formik={formik} />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
