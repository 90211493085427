import type { OutlinedInputProps, TextFieldProps } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import type { FormikErrors, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import type { FocusEventHandler, KeyboardEventHandler } from 'react';
import { useState } from 'react';
import { localeChrono } from '../../utils/date.js';

type Props<ID, T> = TextFieldProps &
  OutlinedInputProps & {
    id: ID;
    formik: FormikProps<T>;
    referenceDate?: Date | null;
  };

export function FormikDateTimeField<
  ID extends string,
  T extends { [key in ID]?: Date | null },
>(props: Props<ID, T>): JSX.Element {
  const {
    formik,
    fullWidth,
    id,
    label,
    margin,
    onInvalid,
    referenceDate,
    size,
    variant,
    ...textProps
  } = props;
  const jsDate = formik.values[props.id];
  const [value, setValue] = useState(
    jsDate
      ? DateTime.fromJSDate(jsDate).toLocaleString(
          DateTime.DATETIME_MED_WITH_WEEKDAY,
        )
      : '',
  );

  const computeDateValue = (
    target: HTMLTextAreaElement | HTMLInputElement,
    beforeUpdate?: () => void,
  ): void => {
    const date = localeChrono.parseDate(
      target.value,
      referenceDate ?? new Date(),
      { forwardDate: true },
    );
    const dateTime = date ? DateTime.fromJSDate(date) : undefined;
    setValue(
      dateTime?.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY) ?? '',
    );
    beforeUpdate?.();
    formik
      .setFieldValue(props.id, date ?? undefined, true)
      .catch(captureException);
  };

  const handleOnBlur: FocusEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    computeDateValue(
      evt.target as HTMLTextAreaElement | HTMLInputElement,
      () => {
        formik.handleBlur(evt);
      },
    );
  };

  const handleOnKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (evt) => {
    if (evt.key !== 'Enter') return;
    computeDateValue(evt.target as HTMLTextAreaElement | HTMLInputElement);
  };

  return (
    <FormControl
      disabled={formik.isSubmitting || props.disabled}
      error={
        (formik.touched[props.id] && Boolean(props.id in formik.errors)) ||
        props.error
      }
      fullWidth={fullWidth ?? true}
      margin={margin}
      onInvalid={onInvalid}
      size={size}
      variant={variant}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        {...textProps}
        id={id}
        disabled={formik.isSubmitting || props.disabled}
        value={value}
        onBlur={handleOnBlur}
        onChange={(evt) => setValue(evt.target.value)}
        onKeyDown={handleOnKeyDown}
        name={props.name ?? props.id}
        fullWidth={fullWidth}
        label={label}
        size={size}
      />
      <FormHelperText id={`${id}-helper-text`}>
        {getError(formik.errors[props.id]) ?? props.helperText}
      </FormHelperText>
    </FormControl>
  );
}

const getError = (
  errors:
    | string
    | string[]
    | FormikErrors<unknown>
    | FormikErrors<unknown>[]
    | undefined,
): string | undefined => (errors ? String(errors) : undefined);
