import type { GeoJSONPoint } from 'core';
import { Event } from 'core';
import omit from 'lodash.omit';
import { useMemo } from 'react';
import { useImmer } from 'use-immer';
import type { Contract } from 'validata';
import { isIssue, isObject } from 'validata';

export interface EventDetailsState {
  all: {
    close?: Date | null;
    competition: boolean;
    defaultControlRadius: number | null;
    eventSeriesId: string | null;
    type?: string | null;
    finish?: Date | null;
    groupIds?: string[] | null;
    labels: Record<string, string>;
    location?: GeoJSONPoint | null;
    organizationIds: string[] | null;
    public: boolean;
    published: boolean;
    start?: Date | null;
    tags: string[];
    title: string;
  };
  eventFileUploaded: boolean;
  projection?: string | null;
  valid: boolean;
}

export type ExportDetails = Omit<
  Event.Create,
  '_id' | 'controls' | 'courses' | 'overlayImage'
>;

export const exportDetailsContract = omit(Event.createContract, [
  '_id',
  'controls',
  'courses',
  'overlayImage',
]) as Contract<ExportDetails>;

export interface EventDetailsActions {
  eventFileDetails: (
    title: string | undefined,
    location: GeoJSONPoint,
    projection: string | undefined,
  ) => void;
  getDetails: () => ExportDetails | false;
  setProjection: (projection: string | null) => void;
  setValid: (valid: boolean) => void;
  update: (details: Partial<EventDetailsState['all']>) => void;
}

export const useEventDetailsState = (
  initial?: Partial<Omit<EventDetailsState, 'all'>> & {
    all: Partial<EventDetailsState['all']>;
  },
): EventDetailsState & EventDetailsActions => {
  const [state, set] = useImmer<EventDetailsState>({
    eventFileUploaded: false,
    valid: false,
    ...initial,
    all: {
      competition: true,
      defaultControlRadius: null,
      eventSeriesId: null,
      groupIds: null,
      labels: {},
      organizationIds: null,
      public: true,
      published: true,
      tags: [],
      title: '',
      ...initial?.all,
    },
  } satisfies EventDetailsState);

  return useMemo(
    () => ({
      ...state,
      getDetails: () => {
        const result = isObject(exportDetailsContract).process(state.all);
        if (isIssue(result)) return false;
        return result.value;
      },
      eventFileDetails: (
        title: string | undefined,
        location: GeoJSONPoint,
        projection: string | undefined,
      ) =>
        set((draft) => {
          draft.all.title = title ?? '';
          draft.all.location = location;
          draft.projection = projection ?? draft.projection;
          draft.eventFileUploaded = true;
        }),
      setProjection: (projection: string | null) =>
        set((draft) => {
          draft.projection = projection;
        }),
      setValid: (valid: boolean) =>
        set((draft) => {
          draft.valid = valid;
        }),
      update: (details) =>
        set((draft) => {
          const all = { ...draft?.all, ...details };
          draft.all = all;
          // TODO validate
        }),
    }),
    [state, set],
  );
};
