import type { TempFile } from 'core';

export interface OverlayState {
  file?: File;
  dimensions?: { width: number; height: number };
  dimensionsError?: string | Event;
  previewSrc?: string;
  projection?: string;
  tempUploadInfo?: TempFile;
  tempUploadError?: Error;
  worldFile?: number[];
  worldFileError?: Error;
}

type Action =
  | { type: 'RESET' }
  | { type: 'IMAGE_SELECTED'; file: File; previewSrc: string }
  | {
      type: 'DIMENSIONS_CALCULATED';
      dimensions: { width: number; height: number };
    }
  | { type: 'DIMENSIONS_ERROR'; error: string | Event }
  | { type: 'TEMP_UPLOAD'; info: TempFile }
  | { type: 'TEMP_UPLOAD_ERROR'; error: Error }
  | { type: 'WORLD_FILE_PARSED'; worldFile: number[] }
  | { type: 'WORLD_FILE_ERROR'; error: Error }
  | { type: 'PROJECTION_SELECTED'; projection: string };

export const reducer = (state: OverlayState, action: Action): OverlayState => {
  switch (action.type) {
    case 'RESET':
      return {};
    case 'IMAGE_SELECTED':
      return {
        ...state,
        file: action.file,
        previewSrc: action.previewSrc,
      };
    case 'DIMENSIONS_CALCULATED':
      return {
        ...state,
        dimensions: action.dimensions,
        dimensionsError: undefined,
      };
    case 'DIMENSIONS_ERROR':
      return {
        ...state,
        dimensions: undefined,
        dimensionsError: action.error,
      };
    case 'TEMP_UPLOAD':
      return {
        ...state,
        tempUploadError: undefined,
        tempUploadInfo: action.info,
      };
    case 'TEMP_UPLOAD_ERROR':
      return {
        ...state,
        tempUploadError: action.error,
        tempUploadInfo: undefined,
      };
    case 'WORLD_FILE_PARSED':
      return {
        ...state,
        worldFile: action.worldFile,
        worldFileError: undefined,
      };
    case 'WORLD_FILE_ERROR':
      return {
        ...state,
        worldFile: undefined,
        worldFileError: action.error,
      };
    case 'PROJECTION_SELECTED':
      return {
        ...state,
        projection: action.projection,
      };
    default:
      return state;
  }
};
