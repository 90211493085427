import { Event } from 'core';
import { useFormik } from 'formik';
import type { FC } from 'react';
import { useEffect } from 'react';
import { formikCheck } from '../../../../utils/validation.js';
import type { EventDetailsState } from '../../store/event-details-state.js';
import type { EventState } from '../../store/event-state.js';
import { EventDetailsStepView } from './view.js';

interface Props {
  edit?: boolean;
  state: EventState;
}

export const EventDetailsStep: FC<Props> = ({ edit, state }) => {
  const formik = useFormik<EventDetailsState['all']>({
    initialValues: state.details.all,
    validate: async (value) => formikCheck(Event.createContract, value),
    validateOnBlur: true,
    onSubmit: () => {},
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: avoid loops
  useEffect(() => {
    state.details.update(formik.values);
  }, [formik.values]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: avoid loops
  useEffect(() => {
    state.details.setValid(formik.isValid);
  }, [formik.isValid]);

  return EventDetailsStepView({
    edit,
    formik,
  });
};
