import type { TempOverlayImage } from 'core';
import { useMemo } from 'react';
import { useImmer } from 'use-immer';
import type { OverlayImage } from '../../../utils/types.js';

export interface OverlayImageState {
  overlayImage?: OverlayImage | null;
  tempFile?: TempOverlayImage | null;
}

export interface OverlayImageActions {
  set: (
    overlayImage?: OverlayImage | null,
    tempFile?: TempOverlayImage | null,
  ) => void;
}

export const useOverlayImageState = (
  initialOverlayImage?: OverlayImage | null,
  initialTempFile?: TempOverlayImage | null,
): OverlayImageState & OverlayImageActions => {
  const [state, set] = useImmer({
    overlayImage: initialOverlayImage,
    tempFile: initialTempFile,
  });
  return useMemo(
    () => ({
      ...state,
      set: (overlayImage, tempFile) => set(() => ({ overlayImage, tempFile })),
    }),
    [state, set],
  );
};
