import { Alert, MenuItem, Skeleton } from '@mui/material';
import type { Group, PaginatedList } from 'core';
import type { FormikProps } from 'formik';
import type { ReactElement } from 'react';
import uri from 'uri-tag';
import { useAxios } from '../../hooks/use-axios.js';
import { FormikMultiSelect } from '../formik-fields/select.js';

export interface SelectGroupsData {
  groupIds?: string[] | null;
}

export type SelectGroupsFormik<T extends SelectGroupsData> = FormikProps<T>;

export function SelectGroups<T extends SelectGroupsData>({
  formik,
}: { formik: SelectGroupsFormik<T> }): ReactElement {
  const [groups] = useAxios<PaginatedList<Group.Type>>(uri`/api/v1/my/groups`);

  if (groups.loading) {
    return <Skeleton variant="text" />;
  }

  if (groups.error) {
    return <Alert severity="error">{groups.error.message}</Alert>;
  }

  if (!groups.data?.items.length) {
    return (
      <Alert severity="info">Couldn't find any groups that you belong to</Alert>
    );
  }

  return (
    <FormikMultiSelect
      id="groupIds"
      label="Group(s)"
      formik={formik}
      helperText="The groups that will have access to this event."
    >
      {groups.data.items.map((group) => (
        <MenuItem key={group._id} value={group._id}>
          {group.title}
        </MenuItem>
      ))}
    </FormikMultiSelect>
  );
}
