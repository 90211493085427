import { LoadingButton } from '@mui/lab';
import type { SxProps } from '@mui/material';
import { FormControl, FormHelperText } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import type { Accept } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

interface Props {
  accept?: Accept | undefined;
  helperText: ReactNode;
  helperTextReject: ReactNode;
  helperTextDragOver: ReactNode;
  onFile: (file: File) => Promise<void>;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps;
}

export const FileDrop: FC<Props> = (props) => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: props.accept,
      maxFiles: 1,
      onDropAccepted: (files) => {
        if (!files.length) return;
        setBusy(true);
        setError(undefined);
        props
          .onFile(files[0])
          .finally(() => setBusy(false))
          .catch((err) => setError(String(err)));
      },
    });

  return (
    <FormControl fullWidth error={!!error}>
      <LoadingButton
        variant="outlined"
        {...getRootProps()}
        loading={busy}
        size={props.size}
        aria-describedby="file-drop-error"
        sx={props.sx}
      >
        <input {...getInputProps()} />
        {isDragActive
          ? isDragReject
            ? props.helperTextReject
            : props.helperTextDragOver
          : props.helperText}
      </LoadingButton>
      {error ? (
        <FormHelperText id="file-drop-error" sx={{ textAlign: 'center' }}>
          {error}
        </FormHelperText>
      ) : undefined}
    </FormControl>
  );
};
