import type { Event } from 'core';
import { toEventControls } from 'core';
import type { CoursesActions, CoursesState } from './courses-state.js';
import { useCoursesState } from './courses-state.js';
import type {
  EventControlsActions,
  EventControlsState,
} from './event-controls-state.js';
import { useControlsState } from './event-controls-state.js';
import type {
  EventDetailsActions,
  EventDetailsState,
} from './event-details-state.js';
import { useEventDetailsState } from './event-details-state.js';
import type {
  OverlayImageActions,
  OverlayImageState,
} from './overlay-image-state.js';
import { useOverlayImageState } from './overlay-image-state.js';

export interface EventState {
  courses: CoursesState & CoursesActions;
  details: EventDetailsState & EventDetailsActions;
  eventControls: EventControlsState & EventControlsActions;
  overlayImage: OverlayImageState & OverlayImageActions;
}

export const useEventState = (
  event?: Partial<Event.WithCourses>,
): EventState => ({
  courses: useCoursesState(event?.courses),
  details: useEventDetailsState({
    all: {
      close: event?.close ?? null,
      competition: event?.competition ?? true,
      defaultControlRadius: event?.defaultControlRadius ?? null,
      eventSeriesId: event?.eventSeriesId ?? null,
      finish: event?.finish ?? null,
      groupIds: event?.groupIds ?? null,
      labels: event?.labels ?? {},
      location: event?.location ?? null,
      organizationIds: event?.organizationIds ?? null,
      public: event?.public ?? true,
      published: event?.published ?? true,
      start: event?.start ?? null,
      tags: event?.tags ?? [],
      title: event?.title ?? '',
      type: event?.type,
    },
    eventFileUploaded: false,
    projection: null,
    valid: false,
  }),
  eventControls: useControlsState(
    event?.controls ? toEventControls(event?.controls) : undefined,
  ),
  overlayImage: useOverlayImageState(
    event?.overlayImage
      ? { url: event.overlayImage.path, corners: event.overlayImage.corners }
      : undefined,
  ),
});
