import { useMemo } from 'react';
import { ulid } from 'ulid';
import { useImmer, type Updater } from 'use-immer';
import type { NewCourse } from '../types.js';
import type { EditActions, EditState } from './edit-state.js';

export interface CoursesState extends EditState<NewCourse> {
  all: NewCourse[];
  valid: boolean;
}

export interface CoursesActions extends EditActions<NewCourse> {
  add: () => void;
  remove: (course: NewCourse) => void;
  reset: (courses: NewCourse[]) => void;
}

export const createCoursesActions = (
  set: Updater<CoursesState>,
): CoursesActions => ({
  add: () =>
    set((draft) => {
      draft.editing = {
        _id: ulid(),
        title: 'New',
        controls: [],
        defaultControlRadius: null,
        tags: [],
        labels: {},
      };
    }),
  remove: (course): void =>
    set((draft) => ({
      all: draft.all.filter((existing) => existing._id !== course._id),
    })),
  reset: (courses): void => set(() => ({ all: courses })),
  // edit
  cancelEdit: () =>
    set((draft) => {
      draft.editing = undefined;
    }),
  edit: (edit) =>
    set((draft) => {
      draft.editing = edit;
    }),
  saveEdit: (update) =>
    set((draft) => {
      const editing = update ?? draft.editing;
      if (!editing) return;

      const index = draft.all.findIndex(({ _id }) => _id === editing._id);
      if (index !== -1) {
        draft.all.splice(index, 1, editing);
      } else {
        draft.all.push(editing);
      }
      draft.editing = undefined;
    }),
  updateEdit: (update) =>
    set((draft) => {
      draft.editing = update;
    }),
});

export const useCoursesState = (
  initial?: NewCourse[],
): CoursesState & CoursesActions => {
  const [state, set] = useImmer<CoursesState>({
    all: initial ?? [],
    valid: true,
  });
  return useMemo(
    () => ({ ...state, ...createCoursesActions(set) }),
    [state, set],
  );
};
