import type { EventControl, GeoJSONPoint } from 'core';
import omit from 'lodash.omit';
import { useMemo } from 'react';
import { useImmer, type Updater } from 'use-immer';
import type { EditActions, EditState } from './edit-state.js';

export interface EventControlsState extends EditState<EventControl> {
  all: Record<string, EventControl>;
  valid: boolean;
}

export interface EventControlsActions extends EditActions<EventControl> {
  add: (_id: string, location: GeoJSONPoint, type: string) => void;
  remove: (id: string) => void;
  reset: (controls: Record<string, EventControl>) => void;
}

export const createEventControlsActions = (
  set: Updater<EventControlsState>,
): EventControlsActions => ({
  add: (_id: string, location: GeoJSONPoint, type: string) =>
    set((draft) => {
      draft.adding = {
        _id,
        location,
        type,
      };
      draft.editing = undefined;
    }),
  remove: (id) =>
    set((draft) => ({
      eventControls: omit(draft.all, id),
    })),
  reset: (controls) =>
    set(() => ({ adding: undefined, all: controls, editing: undefined })),
  // edit
  cancelEdit: () =>
    set((draft) => {
      draft.adding = undefined;
      draft.editing = undefined;
    }),
  edit: (edit) =>
    set((draft) => {
      draft.adding = undefined;
      draft.editing = edit;
    }),
  saveEdit: (update) =>
    set((draft) => {
      const editing = update ?? draft.adding ?? draft.editing;
      if (!editing) return;

      draft.all[editing._id] = editing;
      draft.adding = undefined;
      draft.editing = undefined;
    }),
  updateEdit: (update) => set(() => ({ editing: update })),
});

export const useControlsState = (
  initial?: Record<string, EventControl>,
): EventControlsState & EventControlsActions => {
  const [state, set] = useImmer<EventControlsState>({
    all: initial ?? {},
    valid: true,
  });

  return useMemo(
    () => ({ ...state, ...createEventControlsActions(set) }),
    [state, set],
  );
};
