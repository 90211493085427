import { Box, Card } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

export const PageCard: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box width="100%">
      <Card>{children}</Card>
    </Box>
  );
};
