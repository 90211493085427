import type { AutocompleteProps } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';
import type { FC } from 'react';
import { projectionArray, projections } from './file-processing/projections.js';

interface Props
  extends Omit<
    AutocompleteProps<[string, string], false, false, false>,
    'options' | 'renderInput'
  > {}

const common: [string, string][] = [
  ['NZTM2000 (EPSG:2193)', projections['EPSG:2193']],
];
const allProjections = [...common, ...projectionArray];

export const ProjectionPicker: FC<Props> = (props) => (
  <Autocomplete
    {...props}
    options={allProjections}
    autoHighlight
    getOptionLabel={(option) => option[0]}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Choose a projection"
        inputProps={{
          ...params.inputProps,
          autoComplete: 'off',
        }}
      />
    )}
  />
);
