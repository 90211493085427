import axios from 'axios';
import type { TempFile, TempFileUploadResponse } from 'core';
import type { FC } from 'react';
import uri from 'uri-tag';
import type { EventState } from '../../store/event-state.js';
import { EventMapOverlayView } from './view.js';

interface Props {
  state: EventState;
}

export const EventMapOverlayStep: FC<Props> = ({ state }) => {
  const uploadImageFile = async (file: File): Promise<TempFile | undefined> => {
    const formData = new FormData();
    formData.append('image', file);
    const { data } = await axios.post<TempFileUploadResponse[]>(
      uri`/api/v1/temp-file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return data?.length === 1 && data[0].success ? data[0].file : undefined;
  };

  return EventMapOverlayView({
    ...state,
    uploadImageFile,
  });
};
