import {
  Alert,
  Box,
  Button,
  CardContent,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FileDrop } from '../../../../components/file-drop/view.js';
import { Map as MapControl } from '../../../../components/map/component.js';
import { OverlayImageLayer } from '../../../../components/map/layers/overlay-image.js';
import { MapStyles } from '../../../../components/map/map-styles.js';
import { ProjectionPicker } from '../../projection-picker.js';
import { withViewState } from './view-state.js';

export const EventMapOverlayView = withViewState(
  ({
    bounds,
    corners,
    onImageFile,
    onReset,
    onWorldFile,
    projection,
    setProjection,
    reducedState: state,
  }) => (
    <>
      <CardContent>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Typography variant="h4">Orienteering Map Image Overlay</Typography>
          {state.tempUploadInfo ||
          state.file ||
          state.worldFile ||
          state.projection ? (
            <Button onClick={onReset}>Reset</Button>
          ) : null}
        </Box>
      </CardContent>
      <CardContent>
        <Stack spacing={3}>
          <Typography variant="body1">
            An orienteering map image can be overlaid over the base satellite
            imagery. For this both an image (PNG, JPEG or GIF) as well as a
            world file (PGW, JGW, GFW) must be provided.
          </Typography>
          <Typography variant="body1">
            These files can be exported from OCAD (
            <Link
              href="https://www.ocad.com/wiki/ocad/en/index.php?title=Export_Files#Export_JPEG"
              target="_blank"
            >
              instructions
            </Link>
            ), Purple Pen or OOM. Export of base map, with no controls, works
            best.
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={3}>
            <Stack spacing={3} flex={1}>
              <Typography variant="h6">Overlay Image</Typography>
              {!state.tempUploadInfo ? (
                <FileDrop
                  accept={{
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/png': ['.png'],
                    'image/gif': ['.gif'],
                  }}
                  helperText="Drag 'n' drop image file here, or click to select"
                  helperTextDragOver="Drop here"
                  helperTextReject="Must be a JPEG, PNG or GIF file"
                  onFile={onImageFile}
                  size="large"
                  sx={{ height: '6rem' }}
                />
              ) : null}
              {state.file ? (
                state.tempUploadInfo ? (
                  <Alert severity="success">
                    Image file, <code>{state.file?.name}</code>, ready.
                  </Alert>
                ) : state.tempUploadError ? (
                  <Alert severity="error">
                    Unable to upload image file,{' '}
                    <code>{state.tempUploadError.message}</code>.
                  </Alert>
                ) : (
                  <Alert severity="info">
                    Image file, <code>{state.file?.name}</code>, selected.
                  </Alert>
                )
              ) : null}
            </Stack>
            <Stack spacing={3} flex={1}>
              <Typography variant="h6">World File</Typography>
              {!state.worldFile ? (
                <FileDrop
                  accept={{
                    'text/plain': ['.jgw', '.pgw', '.gfw'],
                  }}
                  helperText="Drag 'n' drop world file here, or click to select"
                  helperTextDragOver="Drop here"
                  helperTextReject="Must be a JGW, PGW or GFW file"
                  onFile={onWorldFile}
                  size="large"
                  sx={{ height: '6rem' }}
                />
              ) : (
                <Alert severity="success">World file loaded.</Alert>
              )}
              {!projection && state.worldFile ? (
                <>
                  <Typography variant="body1">
                    Select the projection used in the file provided.
                  </Typography>
                  <ProjectionPicker
                    onChange={(_, value) => value && setProjection(value[1])}
                  />
                </>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      {bounds ? (
        <Box sx={{ mt: 3 }}>
          <MapControl
            width="100%"
            height={560}
            bounds={bounds}
            mapStyle={MapStyles.SATELLITE}
          >
            {corners && state.previewSrc ? (
              <OverlayImageLayer
                id="preview"
                corners={corners}
                imageUrl={state.previewSrc}
                interpolateOpacity={true}
              />
            ) : null}
          </MapControl>
        </Box>
      ) : null}
    </>
  ),
);
