import type { SwitchProps } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';

type Props<ID, T> = SwitchProps & {
  id: ID;
  error?: boolean;
  formik: FormikProps<T>;
  helperText?: ReactNode;
  label: ReactNode;
};

export function FormikSwitch<
  ID extends string,
  T extends { [key in ID]?: boolean },
>(props: Props<ID, T>): JSX.Element {
  const { formik, label } = props;
  return (
    <FormControl
      component="fieldset"
      error={Boolean(formik.errors[props.id]) || props.error}
      fullWidth
      variant="standard"
    >
      <FormControlLabel
        control={
          <Switch
            id={props.id}
            name={props.name ?? props.id}
            checked={formik.values[props.id]}
            disabled={formik.isSubmitting || props.disabled}
            onBlur={formik.handleBlur}
            onChange={(_, value) => {
              formik.setFieldValue(props.id, value).catch(captureException);
            }}
          />
        }
        disabled={formik.isSubmitting || props.disabled}
        label={label}
      />
      <FormHelperText>
        {formik.errors[props.id]?.toString() || props.error || props.helperText}
      </FormHelperText>
    </FormControl>
  );
}
