import { bbox, helpers } from '@turf/turf';
import type {
  Bounds,
  Corners,
  Position,
  TempFile,
  TempOverlayImage,
} from 'core';
import { bboxToBounds } from 'core';
import proj4 from 'proj4';
import type { ComponentType } from 'react';
import { useEffect, useMemo, useReducer, useState } from 'react';
import type { OverlayImage } from '../../../../utils/types.js';
import { projections } from '../../file-processing/projections.js';
import type { EventState } from '../../store/event-state.js';
import type { OverlayState } from './reducer.js';
import { reducer } from './reducer.js';

interface Props extends EventState {
  uploadImageFile: (file: File) => Promise<TempFile | undefined>;
}

interface ViewProps {
  bounds: Bounds | undefined;
  corners: Corners | undefined;
  onImageFile: (file: File) => Promise<void>;
  onReset: () => void;
  onWorldFile: (file: File) => Promise<void>;
  reducedState: OverlayState;
  projection: string | null | undefined;
  setProjection: (projection: string) => void;
}

export const withViewState =
  <P extends Props>(Component: ComponentType<ViewProps>): React.FC<P> =>
  ({ details, overlayImage: { set: setOverlayImage }, uploadImageFile }) => {
    const [reducedState, dispatch] = useReducer(reducer, {});
    const [coordinateCorners, setCoordinateCorners] = useState<Corners>();

    const setOverlay = (
      overlayImage?: OverlayImage | null,
      tempFile?: TempOverlayImage | null,
    ): void => {
      setOverlayImage(overlayImage, tempFile);
      reducedState.projection && details.setProjection(reducedState.projection);
    };

    const projection = reducedState.projection ?? details.projection;
    useEffect(() => {
      if (
        !projection ||
        !reducedState.dimensions ||
        !reducedState.worldFile ||
        reducedState.worldFile.length !== 6
      ) {
        setCoordinateCorners(undefined);
        return;
      }

      const [a, d, b, e, c, f] = reducedState.worldFile;

      const pixelCorners = [
        [0, 0],
        [reducedState.dimensions.width, 0],
        [reducedState.dimensions.width, reducedState.dimensions.height],
        [0, reducedState.dimensions.height],
      ] as Corners;

      const mapCorners = pixelCorners.map<Position>(([pixelX, pixelY]) => {
        const x = a * pixelX + b * pixelY + c;
        const y = d * pixelX + e * pixelY + f;
        return [x, y];
      });

      const worldCorners = mapCorners.map(([long, lat]) => {
        return proj4<Position>(projection, projections['EPSG:4326'], [
          long,
          lat,
        ]);
      }) as Corners;

      setCoordinateCorners(worldCorners);

      if (reducedState.tempUploadInfo) {
        setOverlay(
          reducedState.previewSrc
            ? {
                corners: worldCorners,
                url: reducedState.previewSrc,
              }
            : null,
          {
            corners: worldCorners,
            image: reducedState.tempUploadInfo,
          },
        );
      }
    }, [projection, reducedState, setOverlay]);

    const onImageFile = async (file: File): Promise<void> => {
      const previewSrc = URL.createObjectURL(file);
      dispatch({ type: 'IMAGE_SELECTED', file, previewSrc });
      const image = new Image();
      image.onload = () => {
        dispatch({
          type: 'DIMENSIONS_CALCULATED',
          dimensions: {
            width: image.naturalWidth,
            height: image.naturalHeight,
          },
        });
      };
      image.onerror = (error) => {
        dispatch({
          type: 'DIMENSIONS_ERROR',
          error,
        });
      };
      image.src = previewSrc;
      await uploadImageFile(file)
        .then((info) => {
          if (info) {
            dispatch({
              type: 'TEMP_UPLOAD',
              info,
            });
          } else {
            dispatch({
              type: 'TEMP_UPLOAD_ERROR',
              error: new Error('Unexpected error uploading file'),
            });
          }
          return;
        })
        .catch((error: Error) => {
          dispatch({
            type: 'TEMP_UPLOAD_ERROR',
            error,
          });
        });
    };

    const onReset = (): void => {
      setOverlay(undefined, undefined);
      setCoordinateCorners(undefined);
      dispatch({ type: 'RESET' });
    };

    const onWorldFile = async (file: File): Promise<void> => {
      const text = await file.text();
      const worldFile = text
        .trim()
        .split('\n')
        .map((line) => Number(line));

      const valid =
        worldFile.length === 6 &&
        worldFile.every((value) => !Number.isNaN(value));
      if (valid) {
        dispatch({
          type: 'WORLD_FILE_PARSED',
          worldFile,
        });
      } else {
        dispatch({
          type: 'WORLD_FILE_ERROR',
          error: new Error('Invalid World File'),
        });
      }
    };

    const bounds = useMemo(
      () =>
        coordinateCorners
          ? bboxToBounds(bbox(helpers.points(coordinateCorners)))
          : undefined,
      [coordinateCorners],
    );

    return (
      <Component
        bounds={bounds}
        corners={coordinateCorners}
        onImageFile={onImageFile}
        onReset={onReset}
        onWorldFile={onWorldFile}
        projection={projection}
        setProjection={(projection) => {
          dispatch({
            type: 'PROJECTION_SELECTED',
            projection,
          });
        }}
        reducedState={reducedState}
      />
    );
  };
