import type { SelectProps } from '@mui/material';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';

type Props<ID, T> = SelectProps & {
  id: ID;
  error?: boolean;
  formik: FormikProps<T>;
  helperText?: ReactNode;
  label: ReactNode;
};

export function FormikSelect<
  ID extends string,
  T extends { [key in ID]?: string | number },
>(props: Props<ID, T>): JSX.Element {
  const { formik, label, ...selectProps } = props;
  return (
    <FormControl
      disabled={formik.isSubmitting || props.disabled}
      error={
        (formik.touched[props.id] && Boolean(formik.errors[props.id])) ||
        props.error
      }
      fullWidth={props.fullWidth ?? true}
    >
      <InputLabel id={`${props.id}-select-label`}>{label}</InputLabel>
      <Select
        {...selectProps}
        name={props.name ?? props.id}
        labelId={`${props.id}-select-label`}
        disabled={formik.isSubmitting || props.disabled}
        label={label}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[props.id]}
      />
      {(formik.touched[props.id] && Boolean(formik.errors[props.id])) ||
        (props.helperText && (
          <FormHelperText>
            {formik.errors[props.id]?.toString() || props.helperText}
          </FormHelperText>
        ))}
    </FormControl>
  );
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function FormikMultiSelect<
  ID extends string,
  T extends { [key in ID]?: string[] | number[] | null },
>(props: Props<ID, T>): JSX.Element {
  const { formik, helperText, label, ...selectProps } = props;
  return (
    <FormControl
      disabled={formik.isSubmitting || props.disabled}
      error={
        (formik.touched[props.id] &&
          Boolean(formik.errors[props.id]?.length)) ||
        props.error
      }
      fullWidth
    >
      <InputLabel id={`${props.id}-select-label`}>{label}</InputLabel>
      <Select
        {...selectProps}
        multiple
        name={props.name ?? props.id}
        labelId={`${props.id}-select-label`}
        disabled={formik.isSubmitting || props.disabled}
        label={label}
        onBlur={formik.handleBlur}
        onChange={(x) => {
          formik.handleChange(x);
        }}
        value={formik.values[props.id] ?? []}
      />
      {(formik.touched[props.id] && Boolean(formik.errors[props.id]?.length)) ||
        (helperText && (
          <FormHelperText>
            {formikErrorText(formik.errors[props.id]) ?? helperText}
          </FormHelperText>
        ))}
    </FormControl>
  );
}

const formikErrorText = (
  errors: unknown | unknown[] | undefined | null,
): string | null => {
  return !errors
    ? null
    : Array.isArray(errors)
      ? errors.join(', ')
      : String(errors); // HACK above
};
